import * as gtag from '~/lib/gtags.client';

import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useLocation,
  useRouteLoaderData,
} from 'react-router';
import React, {useEffect} from 'react';
import {motion, useReducedMotion} from 'framer-motion';
import {BreadcrumbsLink} from '~/components/breadcrumbs';
import {Header} from '~/components/header';
import type {Route} from './+types/root';
import {Toaster} from '~/components/ui/sonner';
import {TooltipProvider} from './components/ui/tooltip';
import {db} from '~/db.server';
import {getAccount} from '~/lib/auth.server';
import {getClientLocales} from 'remix-utils/locales/server';
import {getKindeSession} from '@kinde-oss/kinde-remix-sdk';
import {getToast} from 'remix-toast';
import {toast as notify} from 'sonner';
import styles from './tailwind.css?url';

export const links: Route.LinksFunction = () => {
  return [
    {rel: 'stylesheet', href: styles},
    {rel: 'preconnect', href: 'https://fonts.googleapis.com'},
    {
      rel: 'preconnect',
      href: 'https://fonts.gstatic.com',
      crossOrigin: 'anonymous',
    },
    {
      href: 'https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;600;700&family=Pixelify+Sans:wght@400..700&display=swap',
      rel: 'stylesheet',
    },
  ];
};

export const loader = async ({request}: Route.LoaderArgs) => {
  const {toast, headers} = await getToast(request);
  const locales = getClientLocales(request);

  const account = await getAccount(request);

  let sessionHeaders: Headers = headers;
  if (account) {
    const session = await getKindeSession(request);
    if (session) {
      sessionHeaders = (await session.refreshTokens()) || headers;
    }
  }

  // Get basic edition info
  const currentEdition = await db.edition.findFirst({
    where: {active: true},
    orderBy: {startDate: 'desc'},
    include: {
      themes: {
        select: {name: true},
        where: {main: true},
      },
    },
  });

  let currentEditionParticipants = 0;
  if (currentEdition) {
    currentEditionParticipants = await db.participant.count({
      where: {editionId: currentEdition.id, neuchatel: true},
    });
  }

  return {
    toast,
    account,
    gaTrackingId: process.env.GA_TRACKING_ID,
    env: process.env.NODE_ENV,
    locales,
    clientSecret: process.env.STRIPE_CLIENT_SECRET,
    currentEdition,
    neuchatelAvailable:
      !!currentEdition &&
      currentEditionParticipants <= currentEdition.maxParticipants,
  };
  // {headers: sessionHeaders}
};

export const handle = {
  breadcrumb: () => <BreadcrumbsLink to="/" label="Home" />,
};

export const meta = () => [
  {title: 'Epic Game Jam'},
  {
    description:
      'Create an epic game in 43 hours by yourself or with a team and try to keep up with the crazy stuff happening in the game jam!',
  },
  {
    name: 'og:image',
    content: '/images/2024-banner.jpeg',
  },
];

function Layout({children}: {children: React.ReactNode}) {
  const shouldReduceMotion = useReducedMotion();
  const data = useRouteLoaderData<typeof loader>('root');
  const {gaTrackingId, env} = data ?? {};

  return (
    <html lang="en" className="mx-auto max-w-screen-xl">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.svg" type="image/svg+xml" />
        <Meta />
        <Links />
      </head>
      <TooltipProvider delayDuration={200}>
        <motion.body
          className="relative m-1 flex h-full min-h-[calc(100vh-1rem)] max-w-screen-xl flex-col gap-4 rounded-sm border-2 border-primary bg-background px-2 pb-2 md:m-2"
          initial={{opacity: 0, scaleY: shouldReduceMotion ? 1 : 0.5}}
          animate={{opacity: 1, scaleY: 1}}
          transition={{delay: 0.6}}
        >
          {process.env.NODE_ENV === 'development' || !gaTrackingId ? null : (
            <>
              <script
                async
                src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`}
              />
              <script
                async
                id="gtag-init"
                dangerouslySetInnerHTML={{
                  __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${gaTrackingId}', {
                  page_path: window.location.pathname,
                });
              `,
                }}
              />
            </>
          )}
          <Header />
          {children}
          <ScrollRestoration />
          <Scripts />
          <script
            dangerouslySetInnerHTML={{__html: `window.env = "${env}";`}}
          />
          <Toaster position="top-center" />
        </motion.body>
      </TooltipProvider>
    </html>
  );
}

export default function App({loaderData}: Route.ComponentProps) {
  const {gaTrackingId, toast} = loaderData;
  const location = useLocation();

  useEffect(() => {
    if (gaTrackingId?.length) {
      gtag.pageview(location.pathname, gaTrackingId);
    }
  }, [location, gaTrackingId]);

  useEffect(() => {
    if (toast?.type === 'error') {
      notify.error(toast.message);
    }
    if (toast?.type === 'success') {
      notify.success(toast.message);
    }
  }, [toast]);

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
}

export function ErrorBoundary({error}: Route.ErrorBoundaryProps) {
  let message = 'Oops!';
  let details = 'An unexpected error occurred.';
  let stack: string | undefined;

  if (isRouteErrorResponse(error)) {
    message = error.status === 404 ? '404' : 'Error';
    details =
      error.status === 404
        ? 'The requested page could not be found.'
        : error.statusText || details;
  } else if (import.meta.env.DEV && error && error instanceof Error) {
    details = error.message;
    stack = error.stack;
  }

  return (
    <main className="container mx-auto p-4 pt-16">
      <h1>{message}</h1>
      <p>{details}</p>
      {stack && (
        <pre className="w-full overflow-x-auto p-4">
          <code>{stack}</code>
        </pre>
      )}
    </main>
  );
}
